@font-face {
	font-family: 'Akira';
	src: url("./static/font/Akira_Super_Bold.woff") format("woff");
}

@font-face {
	font-family: 'RocGrotesk';
	src: url("./static/font/FontsFree-Net-RocGrotesk.woff") format("woff");
}

body {
  margin: 0;
  font-family: 'RocGrotesk', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h2 {
	font-family: 'Akira', 'RocGrotesk', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
}

h3 {
	font-family: 'Akira', 'RocGrotesk', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* CUSTOM */

/* Global */
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
	border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

/* Common */
.color-white {
	color: white !important;
}

.color-red {
	color: #e53935 !important;
}

.bg-black {
	background-color: #212121 !important;
}

.bg-grey {
	background-color: #141414 !important;
}

.pd0 {
	padding: 0 !important;
}

.pt20 {
	padding-top: 20px;
}

.pt40 {
	padding-top: 40px;
}

.pt80 {
	padding-top: 80px;
}

.pb10 {
	padding-bottom: 10px;
}

.pb40 {
	padding-bottom: 40px;
}

.pb80 {
	padding-bottom: 80px;
}

.mr15 {
	margin-right: 15px;
}

.center {
	text-align: center;
}

.right {
	text-align: right;
}

.w150 {
	width: 150px;
}

.fsmal {
	font-size: small;
}

/* App */
.app {
	width: calc(100vw - 10px);
	overflow-x: hidden;
	background-color: #141414;
}

/* Header */
/* .navbar.transparent.navbar-inverse .navbar-inner {
	background: rgba(0,0,0,0.4);
} */

.navbar-transition {
	transition: all .5s ease;
	-webkit-transition: all .5s ease;
	-moz-transition: all .5s ease;
	-o-transition: all .5s ease;
}

/* Banner */
.banner-wrapper {
	position: relative;
	padding-top: 65px;
}

.banner-background {
	height: 720px;
	background-size: cover !important;
	background-repeat: no-repeat !important;
	background-position: center !important;
	-webkit-background-size: cover !important;
	-moz-background-size: cover !important;
	-o-background-size: cover !important;
}

.banner-overlay {
	position: absolute;
	top: 50%;
	left: 40px;
	max-width: 500px;
	color: #E5E5E5;
	font-weight: bold;
	font-size: 24px;
	line-height: 30px;
}

@media (min-width: 576px) {
	.banner-overlay {
		max-width: 350px;
	}
}

@media (min-width: 768px) {
	.banner-overlay {
		max-width: 400px;
	}
}

@media (min-width: 992px) {
	.banner-overlay {
		max-width: 450px;
	}
}

@media (min-width: 1200px) {
	.banner-overlay {
		max-width: 500px;
	}
}

/* Community */
.img-container {
	height: 275px; 
	width: 275px;
	position: relative;
}

.img1 {
	position: absolute;
	width: 125px;
	border: solid 1px black;
	left: 0;
	bottom: 0;
}

.img2 {
	position: absolute;
	width: 125px;
	border: solid 1px black;
	right: 0;
	bottom: 0;
}

.img3 {
	position: absolute;
	width: 125px;
	border: solid 1px black;
	top: 0;
	left: 75px;
}

@media (min-width: 576px) {
	
}

@media (min-width: 768px) {
	.img-container {
		height: 350px; 
		width: 425px;
		position: relative;
	}
	.img1 {
		width: 200px;
	}
	.img2 {
		width: 200px;
	}
	.img3 {
		width: 200px;
		left: 125px;
	}
}

.discord-button {
	background-color: #4052DC;
	padding: 12px;
	border-radius: 3px;
}

.discord-button:hover {
	background-color: #EC7227;
}

/* Mint */
.mint {
	margin-left: auto;
	margin-right: 0px;
}

.mint-text {
	padding-top: 0px;
}

.mint-content {
	padding: 15px;
	background-color: black;
}

@media (min-width: 576px) {
	.mint {
		max-width: calc((100vw - 540px) / 2 + 540px);
	}
	.mint-content {
		background-color: black;
	}
}

@media (min-width: 768px) {
	.mint {
		max-width: calc((100vw - 720px) / 2 + 720px);
	}
	.mint-text {
		/* padding-top: 50px; */
		padding-left: 0px !important;
	}
}

@media (min-width: 992px) {
	.mint {
		max-width: calc((100vw - 960px) / 2 + 960px + 10px);
	}
}

@media (min-width: 1200px) {
	.mint {
		max-width: calc((100vw - 1140px) / 2 + 1140px);
	}
	.mint-text {
		padding-top: 100px;
	}
	.mint-content {
		background-color: unset;
	}
}

.parallax {
  /* Set a specific height */
  min-height: 700px;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: right;
  background-repeat: no-repeat;
}

/* @media (min-width: 768px) {
	.parallax {
		background-size: cover;
	}
}

@media (min-width: 992px) {
	.parallax {
		background-size: cover;
	}
} */

@media (min-width: 1200px) {
	.parallax {
		background-size: auto;
	}
}

/* Roadmap */
.timeline {
	height: 100%;
	padding-top: 18px;
}

.timeline-node {
	width: 15px;
	height: 15px;
	margin-left: auto;
	margin-right: auto;
	border: solid 1px #e53935;
	background-color: #e53935;
	transform: rotate(45deg);
}

.timeline-path {
	height: 100%;
	width: 1px;
	margin-left: auto;
	margin-right: auto;
	border: solid 1px #e53935;
	border-left: unset;
}

/* Team */
.team-section {
	background-color: #090909;
}

.member-avatar {
	text-align: center;
	padding-top: 20px;
}

.member-avatar > img {
	width: 150px;
	height: 150px;
	border-radius: 50%;
}

.member-name {
	padding-top: 10px;
	text-align: center;
	text-transform: uppercase;
	font-size: 18px;
	font-weight: bold;
	color: #ffc107;
}

.member-position {
	text-align: center;
	text-transform: uppercase;
	font-size: 12px;
	color: rgb(244, 67, 54);
}

.mint-box{
	display: flex;
	justify-content: space-around;
	align-items: center;
	margin:  2rem 0;
}

.mint-amount-btn {
  border: 2px solid rgb(244, 67, 54);
  border-radius: 3px;
  height: 2rem;
  width: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
	color: rgb(244, 67, 54);
	font-size: 1.8rem;
}

.amount-display {
	height: 2rem;
	border-bottom: 2px solid rgb(244, 67, 54);
	color: rgb(244, 67, 54);
	width: 50%;
	max-width: 150px;
	min-width: 120px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1.8rem;
}

.mint-button{
	height: 3rem;
	width: 100%;
	background: rgb(244, 67, 54);
	color: white;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	font-size: 1.8rem;
	border-radius: 3px;
}

.mint-feedback{
	color: rgb(244, 67, 54);
	text-align: center;
	font-size: 12px;
	letter-spacing: 1px;
	line-height: 16px;
	margin: 5px 0;
}

.supply-row{
	color: rgb(244, 67, 54);
	font-weight: bold;
	/* letter-spacing: 1px; */
}